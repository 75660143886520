:root {
	--white: #ffffff;
	--l-white: #F2F2F2;
	--voilet: #5F4196;
	--d-grey: #413C41;
	--d-grey2: #A0A0A0;
	--d-grey3: #414042;
	--d-yellow: #F29C2B;
	--yellow: #F09B2D;
	--l-grey: #B9B9B9;
	--grey: #DCDCDC;
	--grey2: #F0F0F0;
	--grey3: #E3E3E3;
	--default: #ABABAB;
}
