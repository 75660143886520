/* Reset Css */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
    font-family: 'FontSemibold', sans-serif;	
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
	display: block;
    font-family: 'FontSemibold', sans-serif;	
}
html {
	height: 100%;
    font-family: 'FontSemibold', sans-serif;	
}
body {
	line-height: 1;
	height: 100%;
    font-family: 'FontSemibold', sans-serif;	
}
ol,
ul {
	list-style: none;
}
blockquote,
q {
	quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
	content: "";
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

img {
	max-width: 100%;
	vertical-align: middle;
}
.row {
	margin-right: 0;
	margin-left: 0;
}
li {
	list-style-type: none;
	line-height: 25px;
}
.footer-wrapper {
	.mat-form-field-appearance-outline .mat-form-field-outline, .mat-form-field-label {
		// color: var(--white) !important;
		background-color: var(--l-grey);
		border-radius: 4px;
	}		
}
.mat-stroked-button:not(.mat-button-disabled) {
	border-color: var(--voilet) !important;
}
